import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";

import { initializeApp } from "firebase/app";

import { Inkline, components } from '@inkline/inkline';
import '@inkline/inkline/inkline.scss';
import "./main.scss";

// code from our Firebase console
const firebaseConfig = {
    apiKey: "AIzaSyBL-4hoMnp5UFQrjVaUjQ1NCoKGte3GHrU",
    authDomain: "robin-hood-app.firebaseapp.com",
    projectId: "robin-hood-app",
    storageBucket: "robin-hood-app.appspot.com",
    messagingSenderId: "9520863935",
    appId: "1:9520863935:web:5b5c722a7b8105b125040a"
  };

// Initialize Firebase
initializeApp(firebaseConfig);

const app = createApp(App);

app.use(router);

app.use(Inkline, {
    components
});
    
app.mount('#app');