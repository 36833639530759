<template>
  <i-navbar>
    <i-navbar-brand to="/">
        <img src="./assets/logo.svg" width="32" height="32" class="image -responsive" alt="Responsive image">
    </i-navbar-brand>
    <i-navbar-collapsible>
        <i-nav>
            <i-nav-item to="/">Home </i-nav-item>
            <i-nav-item to="/feed"> Feed </i-nav-item>
            <i-nav-item to="/register" v-if="!isLoggedIn"> Register </i-nav-item>
            <i-nav-item to="/sign-in" v-if="!isLoggedIn"> Login </i-nav-item>
            <i-nav-item @click="handleSignOut" v-if="isLoggedIn"> Sign Out </i-nav-item>
        </i-nav>
    </i-navbar-collapsible>
  </i-navbar>
  <div id="router-view">
    <router-view />
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { getAuth, onAuthStateChanged, signOut } from '@firebase/auth';
  import router from '@/router';

  const isLoggedIn = ref(true);

  let auth;
  onMounted(() => {
      auth = getAuth();
      onAuthStateChanged(auth, (user) => {
          if (user) {
              isLoggedIn.value = true;
          } else {
              isLoggedIn.value = false;
          }
      });
  });
  // eslint-disable-next-line no-unused-vars
  const handleSignOut = () => {
      signOut(auth).then(() => {
          router.push("/");
      })
  };
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 20px;
}
#router-view  { 
  margin-top: 20px; 
  text-align: center;
}
</style>
